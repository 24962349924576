import React, { Component } from "react";
import EmailCaptureForm from "./EmailCaptureForm";
import Button from "components/common/Button";
import { Grid, Typography } from "@material-ui/core";
import axios from "axios";
import ThankYouMessage from "components/ThankYouMessage";

export default class HubspotEmailForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      data: null,
      loading: false
    };
  }

  handleSubmit = async () => {
    if (!this.form.current.isValid()) return;

    this.setState({ loading: true });

    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: this.state.data["Email"]
        }
      ],
      context: {
        pageUri: "https://architect.parametricos.com",
        pageName: "find an architect "
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            "By clicking submit below, you consent to allow Parametricos to store and process the personal information submitted above to contact and provide you the content requested.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "By clicking submit below, you consent to allow Parametricos to store and process the personal information submitted above to contact and provide you the content requested."
            }
          ]
        }
      }
    };

    const postInstance = axios.create({
      headers: {
        "Content-Type": "application/json"
      }
    });

    try {
      await postInstance.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/5684195/bef4b64d-903c-4385-9ef5-5111b5bd09c7",
        JSON.stringify(data)
      );
      this.setState({ loading: false, submitted: true });
    } catch (error) {
      console.error(error);
      alert(
        "There was a problem submitting your email. Please try again or come back later."
      );
      this.setState({ loading: false });
    }
  };

  handleFormChange = data => {
    this.setState({ data: data });
  };

  handleCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
  };

  render() {
    if (this.state.submitted) return <ThankYouMessage />;
    else {
      return (
        <Grid container direction="row" justify="center" spacing={16}>
          <Grid item xs={12} className="my-2">
            <EmailCaptureForm
              onChange={this.handleFormChange}
              innerRef={this.form}
            />
          </Grid>
          <Grid xs={11}>
            <Typography>
              By clicking submit below, you consent to allow Parametricos to
              store and process the personal information submitted above to
              contact and provide you the content requested.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={this.handleCancel}
              fullWidth
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
              disabled={this.state.loading}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}
