import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from "./screens/Home";
import Callback from "./Callback/Callback";
import history from "./history";
import { AppContext } from "AppProvider";

class Routes extends Component {
  handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      this.context.handleAuthentication();
    }
  };

  render() {
    return (
      <Router history={history}>
        <Route exact path="/" render={props => <Home {...props} />} />
        <Route
          path="/login/callback"
          render={props => {
            this.handleAuthentication(props);
            return <Callback {...props} />;
          }}
        />
      </Router>
    );
  }
}

Routes.contextType = AppContext;

export default Routes;
