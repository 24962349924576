import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from "@material-ui/core/styles";
import Routes from "Routes";
import AppProvider from "AppProvider";
import ReactGA from "react-ga";
import config from "config";

const theme = createMuiTheme({
  spacing: 8,
  typography: {
    useNextVariants: true
  },
  palette: {
    common: {
      black: "rgba(65, 64, 66, 1)",
      white: "rgba(241, 241, 241, 1)"
    },
    background: {
      paper: "#fff",
      default: "#fafafa"
    },
    primary: {
      light: "rgba(214, 215, 217, 1)",
      main: "rgba(219, 95, 45, 1)",
      dark: "rgba(219, 95, 45, 1)",
      contrastText: "#fff"
    },
    secondary: {
      light: "rgba(214, 215, 217, 1)",
      main: "rgba(100, 110, 120, 1)",
      dark: "rgba(60, 74, 89, 1)",
      contrastText: "#fff"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  }
});

const styles = theme => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      fontSize: 16
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: 18
      // }
    }
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize(config.google_analytics.TRACKING_CODE);
  }

  render() {
    return (
      <AppProvider>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <main>
            <Routes />
          </main>
        </MuiThemeProvider>
      </AppProvider>
    );
  }
}

export default withStyles(styles)(App);
