import React from "react";
import styled from "styled-components";

import { Button as MUIButton } from "@material-ui/core";

const borderRadius = 5;

const StyledContainedButton = styled(MUIButton)`
  border-radius: ${borderRadius}px !important;
  border-style: none !important;
`;

const StyledOutlinedButton = styled(MUIButton)`
  border-radius: ${borderRadius}px !important;
  border: 2px !important;
  border-style: solid !important;
`;

export default function Button(props) {
  return props.variant === "contained" ? (
    <StyledContainedButton {...props}>{props.children}</StyledContainedButton>
  ) : (
    <StyledOutlinedButton {...props}>{props.children}</StyledOutlinedButton>
  );
}
