import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Auth } from "aws-amplify";
import { AppContext } from "AppProvider";
import styled from "styled-components";
import Admin from "../screens/Admin";
import Firm from "../screens/Firm";

const PointerWrapper = styled.div`
  cursor: pointer;
`;

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      FirmOpen: false,
      adminOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { state } = this.context;
    return (
      <div>
        <Admin
          open={this.state.adminOpen}
          onClose={() => this.setState({ adminOpen: false })}
        />
        <Firm
          open={this.state.FirmOpen}
          onClose={() => this.setState({ FirmOpen: false })}
        />
        <Navbar color="light" light expand="md" className="fixed-top">
          <NavbarBrand href="/">PARAMETRICOS</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <PointerWrapper>
                  <NavLink onClick={() => this.toggle()} href="/">
                    Home
                  </NavLink>
                </PointerWrapper>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Solutions
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/allfeatures">Studio</DropdownItem>
                  <DropdownItem href="/faa">Find an Architect</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Firm
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/about/">About</DropdownItem>
                  <DropdownItem
                    href="https://spectrum.chat/parametricos/"
                    target="_blank"
                  >
                    Community
                  </DropdownItem>
                  <DropdownItem
                    href="https://blog.parametricos.com"
                    target="_blank"
                  >
                    Blog
                  </DropdownItem>
                  <DropdownItem
                    href="https://blog.parametricos.com/news-events"
                    target="_blank"
                  >
                    News & Events
                  </DropdownItem>
                  <DropdownItem href="/contactus/">Contact Us</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <NavItem>
                <PointerWrapper>
                  <PointerWrapper>
                    <NavLink onClick={() => this.toggle()} href="/pricingsaas">
                      Pricing
                    </NavLink>
                  </PointerWrapper>
                </PointerWrapper>
              </NavItem> */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <AccountCircle size="small" />
                </DropdownToggle>
                <DropdownMenu right>
                  {state.user === null && (
                    <DropdownItem onClick={() => Auth.federatedSignIn()}>
                      Sign in
                    </DropdownItem>
                  )}
                  {state.user !== null && (
                    <DropdownItem
                      onClick={() =>
                        Auth.signOut()
                          .then(data => console.log(data))
                          .catch(err => console.log(err))
                      }
                    >
                      Sign out
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  <DropdownItem href="https://app.parametricos.com/">
                    Studio
                  </DropdownItem>
                  <DropdownItem divider />
                  {state.user !== null && state.admin && (
                    <DropdownItem
                      onClick={() => this.setState({ adminOpen: true })}
                    >
                      Admin
                    </DropdownItem>
                  )}

                  {state.user !== null && (
                    <DropdownItem
                      onClick={() => this.setState({ FirmOpen: true })}
                    >
                      Firm Profile
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
NavBar.contextType = AppContext;
