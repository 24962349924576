import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { ForwardGeocode } from "services/GoogleGeocoderService";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Paper } from "@material-ui/core";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 100,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
});

class GoogleGeocoder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });
    console.log(address);
    ForwardGeocode(address, response =>
      this.props.onChange(response.results[0])
    );
  };

  render() {
    const { classes, errorMessage, label } = this.props;
    return (
      <div className={classes.root}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div className={classes.container}>
              <TextField
                variant="outlined"
                fullWidth
                required
                {...getInputProps({
                  placeholder: label
                })}
                error={errorMessage !== ""}
                helperText={errorMessage}
              />
              {suggestions.length > 0 ? (
                <Paper className={classes.paper}>
                  {suggestions.map(suggestion => {
                    return (
                      <MenuItem
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.label}
                        selected={suggestion.active}
                        component="div"
                        style={{
                          fontWeight: suggestion.active ? 500 : 400
                        }}
                      >
                        {suggestion.description}
                      </MenuItem>
                    );
                  })}
                </Paper>
              ) : null}
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
}

GoogleGeocoder.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GoogleGeocoder);
