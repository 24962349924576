import axios from "axios";
import Auth from "Auth/Auth";
import config from "config.js";

export async function CreateFirm(firm) {
  const token = Auth.getAccessToken();

  const instance = axios.create({
    crossdomain: true,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });

  try {
    return await instance.post(`${config.api.ENDPOINT}/firms`, firm);
  } catch (e) {
    alert(e);
    console.log(e);
  }
}

export async function ListUserFirms() {
  const token = Auth.getAccessToken();
  const instance = axios.create({
    crossdomain: true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  try {
    const response = await instance.get(`${config.api.ENDPOINT}/firms`);
    //console.log(response);
    return response.data;
  } catch (e) {
    alert(e);
    console.log(e);
  }
}

export default {
  CreateFirm,
  ListUserFirms
};
