import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import FindAnArchitectLogo from "images/logo/findanarchitect.png";
import ReactGA from "react-ga";

import { AppContext } from "../AppProvider";
import { isEmpty } from "Tools";
import styled from "styled-components";
import CreateFirmForm from "./CreateFirmForm";

const StyledDialog = styled(Dialog)``;
const LogoContainer = styled.div`
  margin: 10 0px;
`;

class FirmFormDialog extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    ReactGA.modalview("Pre submission - Firm form");
  }

  state = {
    data: null,
    loading: false
  };

  handleFormChange = data => {
    this.setState({ data: data });
  };

  render() {
    const { loading } = this.state;
    const { userArchitects } = this.context.state;

    return (
      <StyledDialog
        fullWidth
        fullScreen={window.innerWidth < 600}
        maxWidth="sm"
        open={true}
      >
        <DialogTitle>
          <LogoContainer>
            <img
              src={FindAnArchitectLogo}
              height="30px"
              alt="'find an architect' logo"
            />
          </LogoContainer>
          <div>Register your firm below</div>
        </DialogTitle>
        <DialogContent>
          {loading && <Typography>Loading...</Typography>}
          {!loading && !isEmpty(userArchitects) && (
            <Typography>
              Thank you for submitting your firm! Your information will be
              validated by our team soon.
            </Typography>
          )}
          {!loading && isEmpty(userArchitects) && <CreateFirmForm />}
        </DialogContent>
      </StyledDialog>
    );
  }
}

FirmFormDialog.contextType = AppContext;

export default FirmFormDialog;
