import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import EnhancedTable from "components/ArchitectTable";
import ArchitectDialog from "components/ArchitectDialog";
import { AppContext } from "AppProvider";
const drawerWidth = 240;

const styles = theme => ({
  flex: {
    flex: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    marginLeft: drawerWidth
  },
  toolbar: theme.mixins.toolbar
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Admin extends React.Component {
  state = {
    selectedFirm: null,
    newFormOpen: false,
    editDialogOpen: false
  };
  handleEditFirm = Firm => {
    var result = this.context.state.allArchitects.filter(obj => {
      return obj.id === Firm;
    });
    this.setState({ selectedFirm: result[0], editDialogOpen: true });
  };

  renderEditDialog = () => {
    const { selectedFirm, editDialogOpen } = this.state;
    if (editDialogOpen && selectedFirm) {
      return (
        <ArchitectDialog
          data={selectedFirm}
          open={true}
          handleClose={() =>
            this.setState({ editDialogOpen: false, selectedFirm: null })
          }
        />
      );
    }
    return null;
  };
  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Admin
            </Typography>
            <Button color="inherit" onClick={onClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List>
            <ListItem button>
              <ListItemText primary="Architects" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Team" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <EnhancedTable
            data={this.context.state.allArchitects}
            handleEdit={this.handleEditFirm}
          />
          {this.renderEditDialog()}
        </main>
      </Dialog>
    );
  }
}

Admin.propTypes = {
  classes: PropTypes.object.isRequired
};

Admin.contextType = AppContext;
export default withStyles(styles)(Admin);
