import config from "config.js";

import Geocode from "react-geocode";

export function ForwardGeocode(query, callback) {
  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey(config.google_maps.API_KEY);

  // Get latidude & longitude from address.
  Geocode.fromAddress(query).then(
    response => {
      callback(response);
    },
    error => {
      console.error(error);
      callback(null);
    }
  );
}

export default {
  ForwardGeocode
};
