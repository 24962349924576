import React from "react";
import Typography from "@material-ui/core/Typography";
import MapGL, { Marker } from "react-map-gl";
import styled from "styled-components";
import Geocoder from "react-map-gl-geocoder";
import ArchitectPin from "components/Map/ArchitectPin";
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiaGhhbW1lcml0ZSIsImEiOiJjamExMmh1bWwxemVwMnFwb3l3am54eTRkIn0.QXzlX9VcL_oG9PkefHhl3Q"; // Set your mapbox token here

const MapContainer = styled.div`
  width: 80vw;
  height: 50vh;
`;
class FirmAddressForm extends React.Component {
  state = {
    viewport: {
      latitude: 37.7577,
      longitude: -122.4376,
      zoom: 8
    },
    searchResult: null
  };

  mapRef = React.createRef();

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  };

  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    const { handleChange } = this.props;
    console.log(event.result);
    this.setState({
      searchResult: event.result
    });
    handleChange(event.result);
  };

  render() {
    const { viewport, searchResult } = this.state;

    return (
      <MapContainer>
        <Typography variant="h6" className="text-center">
          Search for your office on the map
        </Typography>
        <MapGL
          width="100%"
          height="100%"
          ref={this.mapRef}
          {...viewport}
          onViewportChange={this.handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <Geocoder
            mapRef={this.mapRef}
            onResult={this.handleOnResult}
            onViewportChange={this.handleGeocoderViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            position="top-left"
          />
          {searchResult != null && (
            <Marker
              latitude={searchResult.center[1]}
              longitude={searchResult.center[0]}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <ArchitectPin size={30} />
            </Marker>
          )}
        </MapGL>
      </MapContainer>
    );
  }
}

export default FirmAddressForm;
