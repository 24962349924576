import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Link
} from "@material-ui/core";
import { AppContext } from "../AppProvider";
import styled from "styled-components";
import FindAnArchitectLogo from "images/logo/findanarchitect.png";
import Button from "components/common/Button";
import HubspotEmailForm from "./Forms/HubspotEmailForm";
import ReactGA from "react-ga";

const Logo = styled.img`
  width: 100%;
`;

const LogoContainer = styled.div`
  width: 100%;
  min-width: ;
`;

class WelcomeUnauthDialog extends Component {
  state = {
    showSignupForUpdates: false
  };

  componentDidMount() {
    ReactGA.modalview("Welcome Modal");
  }

  renderHubspotForm = () => {
    return (
      <Grid
        container
        direction="column"
        spacing={8}
        alignContent="center"
        justify="center"
      >
        <Grid item xs={10}>
          <LogoContainer>
            <Logo src={FindAnArchitectLogo} />
          </LogoContainer>
        </Grid>
        <Grid item xs={10} className="my-4">
          <Typography align="left" variant="h5">
            Sign up for updates
          </Typography>
          <HubspotEmailForm
            onCancel={() => this.setState({ showSignupForUpdates: false })}
          />
        </Grid>
      </Grid>
    );
  };

  renderWelcomeContent = () => {
    return (
      <Grid
        container
        direction="column"
        spacing={8}
        alignContent="stretch"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12} sm={10}>
          <Typography variant="h4" align="center">
            Welcome to
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <LogoContainer>
            <Logo src={FindAnArchitectLogo} />
          </LogoContainer>
          <Typography variant="subtitle2" align="right" color="secondary">
            powered by{" "}
            <Link href="https://parametricos.com" target="_blank">
              <b>Parametricos</b>
            </Link>
          </Typography>
        </Grid>
        {/* <Grid item xs={10}>
        <Typography variant="h2" align="center" color="primary">
          find an architect
        </Typography>
        <Typography variant="subtitle2" align="right" color="secondary">
          powered by Parametricos
        </Typography>
      </Grid> */}
        <Grid item xs={12} className="my-4">
          <Typography variant="subtitle2" align="center">
            Search for an architecture firm in any location and narrow down your
            search by firm name, project types and project location.
          </Typography>
        </Grid>
        <Grid item xs={12} />

        <Grid item xs={12}>
          <Grid container direction="row" spacing={16} justify="center">
            <Grid item>
              <Button
                fullWidth={true}
                variant="outlined"
                color="primary"
                onClick={() => this.context.login()}
              >
                Register my firm
              </Button>
            </Grid>
            <Grid item>
              {/* <Button
                fullWidth={true}
                variant="outlined"
                color="primary"
                onClick={() => this.context.logout()}
              >
                logout
              </Button> */}
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => this.setState({ showSignupForUpdates: true })}
              >
                Sign up for Updates
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <Dialog
        fullWidth
        // fullScreen={window.innerWidth < 600}
        maxWidth="sm"
        open={true}
      >
        <DialogContent>
          {this.state.showSignupForUpdates
            ? this.renderHubspotForm()
            : this.renderWelcomeContent()}
        </DialogContent>
      </Dialog>
    );
  }
}

WelcomeUnauthDialog.contextType = AppContext;

export default WelcomeUnauthDialog;
