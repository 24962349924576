import React, { Component } from "react";
import Joi from "joi-browser";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  field: {
    marginTop: 10
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

class EmailCaptureForm extends Component {
  schema = {
    Email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .min(4)
      .required()
  };

  state = {
    data: [],
    errors: []
  };

  isValid = () => {
    if (isEmpty(this.validate())) {
      return true;
    } else {
      return false;
    }
  };

  validateProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const { onValidate } = this.props;
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) {
      if (onValidate) onValidate(true);
      return null;
    }
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    this.setState({ errors });
    if (onValidate) onValidate(false);
    return errors;
  };

  handleChange = (name, value) => {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });

    if (this.props.onChange != null) {
      this.props.onChange(data);
    }
  };

  handleInputChange = ({ target: input }) => {
    this.handleChange(input.name, input.value);
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.field} xs={12}>
            {this.renderTextField(
              "Email",
              "email",
              "Please enter a valid email."
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  renderTextField(name, type, errorMessage) {
    const { errors } = this.state;
    return (
      <TextField
        name={name}
        label={name}
        required
        fullWidth
        variant="outlined"
        error={errors[name] !== undefined}
        onChange={this.handleInputChange}
        helperText={errors[name] !== undefined ? errorMessage : ""}
      />
    );
  }
}

export default withStyles(styles)(EmailCaptureForm);
