import React, { PureComponent } from "react";

const ICON = `M17.77.09C6.21,1.18-1.7,12.38.31,23.82,2.36,35.43,13.07,39,18.39,52a1.41,1.41,0,0,0,2.61,0c5.89-14.46,18.41-17.28,18.41-32.33A19.72,19.72,0,0,0,17.77.09Zm1.92,34.37A14.74,14.74,0,1,1,34.43,19.72,14.73,14.73,0,0,1,19.69,34.46Z`;

const pinStyle = {
  cursor: "pointer",
  fill: "#db5f2d",
  stroke: "none"
};

export default class ArchitectPin extends PureComponent {
  render() {
    const { size = 20, onClick } = this.props;

    return (
      <svg
        height={size}
        viewBox="0 0 39.41 52.93"
        style={{
          ...pinStyle,
          transform: `translate(${-size / 2}px,${-size}px)`
        }}
        onClick={onClick}
      >
        <path d={ICON} />
      </svg>
    );
  }
}
