import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { AppContext } from "../AppProvider";

class LoadingDialog extends Component {
  render() {
    return (
      <Dialog fullWidth maxWidth="sm" open={true}>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={8}
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

LoadingDialog.contextType = AppContext;

export default LoadingDialog;
