import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
const Joi = require("joi-browser");

const Container = styled.div`
  width: 600px;
`;

class FirmInfoForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      errors: {},
      data: { ...props.data }
    };
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
  }

  schema = {
    FirmName: Joi.string()
      .alphanum()
      .min(1)
      .max(30)
      .required(),
    FirmEmail: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required(),
    FirmWebsite: Joi.string()
      .uri()
      .required(),
    FirmTagline: Joi.string()
      .max(140)
      .required(),
    FirmDescription: Joi.string()
      .min(5)
      .max(300)
      .required()
  };

  validate = () => {
    const { error } = Joi.validate(this.state.data, this.schema);
    if (!error) {
      console.log("true");
      return true;
    }
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    this.setState({ errors });
    console.log("false");
    return false;
  };

  handleChange(event) {
    const { handleChange, handleValidation } = this.props;
    const { name, value } = event.target;
    const data = { ...this.state.data };
    data[name] = value;
    handleChange(event);

    this.setState({ data }, () => {
      handleValidation(this.validate());
    });
  }
  render() {
    const { data } = this.state;
    return (
      <Container>
        <Typography variant="h6" gutterBottom>
          Firm Information
        </Typography>
        <Grid container spacing={24}>
          <Grid item xs="12" sm="6">
            <TextField
              variant="outlined"
              required
              id="FirmName"
              name="FirmName"
              label="Firm Name"
              value={data["FirmName"]}
              fullWidth
              autoComplete="Firm-name"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs="12" sm="6">
            <TextField
              required
              variant="outlined"
              id="FirmEmail"
              name="FirmEmail"
              label="Firm Email"
              value={data["FirmEmail"]}
              fullWidth
              autoComplete="Firm-email"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs="12" sm="6">
            <TextField
              required
              variant="outlined"
              id="FirmWebsite"
              name="FirmWebsite"
              label="Firm Website"
              value={data["FirmWebsite"]}
              fullWidth
              autoComplete="Firm-website"
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs="12" sm="12">
            <TextField
              variant="outlined"
              multiline
              rows="3"
              required
              id="FirmTagline"
              name="FirmTagline"
              value={data["FirmTagline"]}
              label="Tagline"
              helperText="Describe your Firm in a single phrase. Max 140 Characters"
              placeholder="Describe your Firm in a single phrase"
              fullWidth
              autoComplete="Firm s-Description"
              onChange={this.handleChange}
              inputProps={{ maxLength: 140 }}
            />
          </Grid>
          <Grid item xs="12" sm="12">
            <TextField
              required
              multiline
              rows="6"
              variant="outlined"
              id="FirmDescription"
              name="FirmDescription"
              label="Description"
              value={data["FirmDescription"]}
              helperText="Describe your Firm in more detail. Max 300 Characters"
              placeholder="Describe your Firm in more detail ."
              fullWidth
              autoComplete="Firm l-Description"
              onChange={this.handleChange}
              inputProps={{ maxLength: 300 }}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default FirmInfoForm;
