const local = {
  auth0: {
    DOMAIN: "parametricos-beta.eu.auth0.com",
    CLIENT_ID: "4H8Md66jwmtHZ8IN4LkheeUUB1hLs8kX",
    CALLBACK_URL: "http://localhost:3000/login/callback",
    AUDIENCE: "parametricos"
  },
  api: {
    ENDPOINT: "https://api.architect.parametricos.com/dev"
  },
  mapbox: {
    TOKEN:
      "pk.eyJ1IjoicGFyYW1ldHJpY29zIiwiYSI6ImNqdnh4cXpjeDA5emIzeXBiM3h4cnI4amcifQ.YSenm1Cwj-b8YlFbeEt-qg"
  },
  google_maps: {
    API_KEY: "AIzaSyA9Q5GyXQceTqnfIrMS0ko0j9J08_E411I"
  },
  s3: {
    REGION: "eu-central-1",
    BUCKET: "find-an-architect-api-dev-attachmentsbucket-7vnexnxahyig"
  },
  google_analytics: {
    TRACKING_CODE: "UA-133305006-1"
  }
};

const dev = {
  auth0: {
    DOMAIN: "parametricos-beta.eu.auth0.com",
    CLIENT_ID: "CE4FUD2UkOTLrW6TptcjV9wYUvsbYyd1",
    CALLBACK_URL: "https://beta.architect.parametricos.com/login/callback",
    AUDIENCE: "parametricos"
  },
  api: {
    ENDPOINT: "https://api.architect.parametricos.com/dev"
  },
  mapbox: {
    TOKEN:
      "pk.eyJ1IjoicGFyYW1ldHJpY29zIiwiYSI6ImNqdnh4cXpjeDA5emIzeXBiM3h4cnI4amcifQ.YSenm1Cwj-b8YlFbeEt-qg"
  },
  google_maps: {
    API_KEY: "AIzaSyA9Q5GyXQceTqnfIrMS0ko0j9J08_E411I"
  },
  s3: {
    REGION: "eu-central-1",
    BUCKET: "find-an-architect-api-dev-attachmentsbucket-7vnexnxahyig"
  },
  google_analytics: {
    TRACKING_CODE: "UA-133305006-1"
  }
};

const prod = {
  auth0: {
    DOMAIN: "parametricos-auth.eu.auth0.com",
    CLIENT_ID: "TRsifkS6zjxROscu1NpjijeARZUdnY5S",
    CALLBACK_URL: "https://architect.parametricos.com/login/callback",
    AUDIENCE: "parametricos"
  },
  api: {
    ENDPOINT: "https://api.architect.parametricos.com/prod"
  },
  mapbox: {
    TOKEN:
      "pk.eyJ1IjoicGFyYW1ldHJpY29zIiwiYSI6ImNqdnh4cXpjeDA5emIzeXBiM3h4cnI4amcifQ.YSenm1Cwj-b8YlFbeEt-qg"
  },
  google_maps: {
    API_KEY: "AIzaSyA9Q5GyXQceTqnfIrMS0ko0j9J08_E411I"
  },
  s3: {
    REGION: "eu-central-1",
    BUCKET: "find-an-architect-api-prod-attachmentsbucket-1lb9nwcxkrm5g"
  },
  google_analytics: {
    TRACKING_CODE: "UA-133305006-1"
  }
};

// Default to dev if not set
var config = (function() {
  switch (process.env.REACT_APP_STAGE) {
    case "prod":
      return prod;
    case "dev":
      return dev;
    case "local":
      return local;
    default:
      return local;
  }
})(local);

export default {
  ...config
};
