import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FirmInfoForm from "components/FirmInfoForm";
import FirmAddressForm from "components/FirmAddressForm";
import Review from "components/Review.jsx";
import styled from "styled-components";
import Background from "images/patterns/topography.svg";
import { Grid } from "@material-ui/core";
import architectService from "services/architectService";
import { AppContext } from "AppProvider";
const Wrapper = styled.div`
  // background-image: url(${Background});
  // background-color: #ffffff;
  // background-size: cover;
`;

const styles = theme => ({
  layout: {},
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  }
});

const steps = ["Firm Information", "Firm Address", "Thank You!"];

class NewForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      valid: false,
      error: "",
      activeStep: 0,
      FirmName: "",
      FirmEmail: "",
      FirmWebsite: "",
      FirmTagline: "",
      FirmDescription: "",
      location: {}
    };
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
  }

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <FirmInfoForm
            data={this.state.data}
            handleValidation={this.handleValidation}
            handleChange={this.handleChange}
          />
        );
      case 1:
        return (
          <FirmAddressForm
            handleValidation={this.handleValidation}
            handleChange={this.handleMapLocationChange}
          />
        );
      case 2:
        return (
          <Review
            handleValidation={this.handleValidation}
            handleChange={this.handleChange}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  handleNext = async () => {
    const { activeStep } = this.state;

    if (activeStep === steps.length - 1) {
      const result = await this.createArchitect();
      if (!result) {
        console.log("Failed to submit form!");
        this.setState({ error: "Failed to submit form" });
      }
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  createArchitect = async () => {
    const {
      FirmName,
      FirmEmail,
      FirmWebsite,
      FirmTagline,
      FirmDescription,
      location
    } = this.state;
    const token = this.context.state.idToken;
    try {
      await architectService.createArchitect(
        FirmName,
        FirmEmail,
        FirmWebsite,
        FirmTagline,
        FirmDescription,
        location,
        token
      );
      return true;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("There was an error creating a new architect.");
        return false;
      }
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  handleValidation = valid => {
    this.setState({
      valid
    });
  };

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleMapLocationChange = location => {
    this.setState({ location });
  };

  render() {
    const { classes } = this.props;
    const { activeStep, error, valid } = this.state;

    return (
      <React.Fragment>
        <Wrapper>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Map my office!
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <form>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    {error === "" && (
                      <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                          Your office is on it's way to the map!
                        </Typography>
                        <Typography variant="subtitle1">
                          Thank you for your form submission. We will verify
                          your Firm’s details as soon as possible and get back
                          to you.
                          <br />
                          <br />
                          If you do have enquires or questions please don't
                          hesitate to email us at support@parametricos.com or
                          even better, start a chat when you click on the chat
                          icon below!
                        </Typography>
                      </React.Fragment>
                    )}
                    {error !== "" && (
                      <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                          Oops! An error has occured.
                        </Typography>
                        <Typography variant="subtitle1">
                          Something went wrong during the submission. Please
                          feel free to try again or contact
                          support@parametricos.com for help.
                        </Typography>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>{this.getStepContent(activeStep)}</Grid>
                    </Grid>
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={this.handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        disabled={!valid}
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </form>
          </Paper>
        </Wrapper>
      </React.Fragment>
    );
  }
}

NewForm.propTypes = {
  classes: PropTypes.object.isRequired
};
NewForm.contextType = AppContext;

export default withStyles(styles)(NewForm);
