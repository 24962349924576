import config from "config.js";
import { Auth, API } from "aws-amplify";

const axios = require("axios");
const apiEndpoint = config.apiUrl + "architects";

export async function getVerifiedArchitects() {
  const { data } = await axios.get(`${apiEndpoint}/getverified`);
  return data.body.architects;
}

export async function getAllArchitects(token) {
  const instance = axios.create({
    headers: {
      Authorization: token
    }
  });

  const { data } = await instance.get(`${apiEndpoint}/getall`);
  return data.body.architects;
}

export async function getUserData(token) {
  const instance = axios.create({
    crossdomain: true,
    headers: {
      Authorization: token
    }
  });

  const { data } = await instance.get(`${apiEndpoint}/getmydata`);
  return data.data.Items;
}

export async function createArchitect(
  name,
  email,
  website,
  tagline,
  speciality,
  tags,
  description,
  location,
  token
) {
  console.log(token);

  const instance = axios.create({
    headers: {
      Authorization: token
    }
  });

  const { data } = await instance.post(apiEndpoint + "/create", {
    name,
    email,
    website,
    tagline,
    description,
    speciality,
    tags,
    location
  });
  return data;
}

export async function updateArchitect(newData, token) {
  const instance = axios.create({
    headers: {
      Authorization: token
    }
  });
  const { data } = await instance.put(apiEndpoint + "/update", {
    ...newData
  });
  return data;
}

export default {
  createArchitect,
  updateArchitect,
  getUserData,
  getVerifiedArchitects,
  getAllArchitects
};
