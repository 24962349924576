import React, { Component } from "react";
import { AppContext } from "AppProvider";
import FirmFormDialog from "components/FirmFormDialog";
import { isEmpty } from "Tools";
import LoadingDialog from "./LoadingDialog";
import WelcomeUnauthDialog from "components/WelcomeUnauthDialog";
import ThankYouMessage from "./ThankYouMessage";
class WelcomeDialog extends Component {
  render() {
    const { user, userArchitects, loading } = this.context.state;

    return (
      <div>
        {loading && <LoadingDialog />}
        {!loading && isEmpty(user) && <WelcomeUnauthDialog />}
        {!loading && isEmpty(userArchitects) && !isEmpty(user) && (
          <FirmFormDialog />
        )}
        {!loading && !isEmpty(userArchitects) && !isEmpty(user) && (
          <ThankYouMessage />
        )}
      </div>
    );
  }
}

WelcomeDialog.contextType = AppContext;

export default WelcomeDialog;
