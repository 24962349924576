import React, { Component } from "react";
import architectService from "services/architectService";
import { ListUserFirms } from "services/FirmService";
import Auth from "Auth/Auth";
import ReactGA from "react-ga";
import config from "config";

export const AppContext = React.createContext();

export default class AppProvider extends Component {
  constructor(props) {
    super(props);

    const auth = new Auth();

    this.state = {
      auth: auth,
      admin: false,
      user: null,
      idToken: null,
      tokens: null,
      userInfo: null,
      userArchitects: null,
      verifiedArchitects: [],
      allArchitects: [],
      loading: false
    };
  }
  async componentDidMount() {
    //Resources below require Authentication.

    const { auth } = this.state;

    if (auth.isAuthenticated()) {
      console.log("Authenticated.");
      auth.getProfile((error, profile) => {
        // console.log(profile);
        this.setState({ user: profile });

        ReactGA.set({ userId: profile.sub });
      });
    } else {
      ReactGA.initialize(config.google_analytics.TRACKING_CODE);
      return;
    }
    this.setState({ loading: true });

    // await Auth.currentAuthenticatedUser()
    //   .then(user => this.setState({ user }))
    //   .catch(() => console.log("Not signed in"));

    // await Auth.currentUserInfo()
    //   .then(userInfo => this.setState({ userInfo }))
    //   .catch(() => console.log("Not signed in"));

    // await Auth.currentSession()
    //   .then(tokens => {
    //     let admin = false;
    //     const groups = tokens.idToken.payload["cognito:groups"];
    //     if (groups) {
    //       admin = groups.includes("Admin");
    //     }

    //     this.setState({
    //       tokens,
    //       idToken: tokens.idToken.jwtToken,
    //       admin
    //     });
    //   })
    //   .catch(err => console.log(err));

    const getUserArchitectsResult = await this.getUserArchitects();
    console.log(getUserArchitectsResult);
    if (!getUserArchitectsResult) {
      console.log("Failed to get user architects!");
    }
    // const getAllArchitectsResult = await this.getAllArchitects();
    // if (!getAllArchitectsResult) {
    //   console.log("Failed to get all architects!");
    // }
    this.setState({ loading: false });
  }

  getVerifiedArchitects = async () => {
    try {
      const verifiedArchitects = await architectService.getVerifiedArchitects();
      this.setState({ verifiedArchitects });
      return true;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("There was an error getting architect information.");
        return false;
      }
    }
  };

  getUserArchitects = async () => {
    try {
      const userArchitects = await ListUserFirms();
      console.log(userArchitects);
      this.setState({ userArchitects });
      return true;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("There was an error getting architect information.");
        return false;
      }
    }
  };

  getAllArchitects = async () => {
    const token = this.state.tokens.idToken.jwtToken;
    try {
      const allArchitects = await architectService.getAllArchitects(token);
      this.setState({ allArchitects });
      return true;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        console.log("There was an error getting architect information.");
        return false;
      }
    }
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: {
            auth: this.state.auth,
            loading: this.state.loading,
            admin: this.state.admin,
            user: this.state.user,
            userArchitects: this.state.userArchitects,
            userInfo: this.state.userInfo,
            verifiedArchitects: this.state.verifiedArchitects,
            allArchitects: this.state.allArchitects,
            tokens: this.state.tokens,
            idToken: this.state.idToken
          },
          handleAuthentication: () => {
            this.state.auth.handleAuthentication();
          },
          login: () => {
            this.state.auth.login();
          },
          logout: () => {
            this.state.auth.logout();
          },
          createArchitect: () => {},
          refreshData: this.init
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
