import React, { Component } from "react";
import Geocoder from "react-map-gl-geocoder";
import MapGL, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl
} from "react-map-gl";

import ArchitectPin from "components/Map/ArchitectPin";
import ArchitectInfo from "components/Map/ArchitectInfo";
import styled from "styled-components";
import { AppContext } from "AppProvider";
import "mapbox-gl/dist/mapbox-gl.css";

import config from "config";

const MAPBOX_TOKEN = config.mapbox.TOKEN;

const StyledPopup = styled(Popup)`
  border-radius: 4px;
  border-width: 1px;
  border-color: black;
  border-style: solid;
  background-color: white;
`;

const fullscreenControlStyle = {
  position: "absolute",
  top: 60,
  left: 0,
  padding: "10px"
};

const navStyle = {
  position: "absolute",
  top: 100,
  left: 0,
  padding: "10px"
};

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 37.785164,
        longitude: -100,
        zoom: 3.5,
        bearing: 0,
        pitch: 0
      },
      popupInfo: null
    };
  }

  mapRef = React.createRef();

  updateViewport = viewport => {
    this.setState({ viewport });
  };

  renderCityMarker = (architect, index) => {
    return (
      <Marker
        key={`architect-${architect.id}`}
        longitude={architect.location.center[0]}
        latitude={architect.location.center[1]}
      >
        <ArchitectPin
          size={30}
          onClick={() => this.setState({ popupInfo: architect })}
        />
      </Marker>
    );
  };

  _renderPopup() {
    const { popupInfo } = this.state;

    return (
      popupInfo && (
        <StyledPopup
          tipSize={5}
          anchor="top-right"
          closeButton={true}
          longitude={popupInfo.location.center[0]}
          latitude={popupInfo.location.center[1]}
          closeOnClick={false}
          offsetLeft={-3}
          offsetTop={3}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <ArchitectInfo info={popupInfo} />
        </StyledPopup>
      )
    );
  }

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.updateViewport({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleGeocodeOnResult = event => {
    this.setState({
      searchResult: event.result
    });
  };

  render() {
    const { viewport } = this.state;
    const { verifiedArchitects } = this.context.state;

    return (
      <MapGL
        {...viewport}
        ref={this.mapRef}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/light-v9"
        onViewportChange={this.updateViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        {this._renderPopup()}
        {verifiedArchitects.map(this.renderCityMarker)}

        {/* <div className="fullscreen" style={fullscreenControlStyle}>
          <FullscreenControl />
        </div>
        <div className="nav" style={navStyle}>
          <NavigationControl onViewportChange={this.updateViewport} />
        </div> */}

        {/* <Geocoder
          mapRef={this.mapRef}
          onResult={this.handleGeocodeOnResult}
          onViewportChange={this.handleGeocoderViewportChange}
          mapboxApiAccessToken={TOKEN}
          position="top-right"
        /> */}

        {/* Seems very buggy */}
        {/* <div style={geolocateStyle}>
          <GeolocateControl
            onViewportChange={this.updateViewport}
            positionOptions={{ enableHighAccuracy: true, timeout: 6000 }}
            showUserLocation={true}
          />
        </div> */}
      </MapGL>
    );
  }
}

Map.contextType = AppContext;
