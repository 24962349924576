import React from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput
} from "@material-ui/core";
import architectService from "services/architectService";
import { AppContext } from "AppProvider";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
});

class ArchitectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      saving: false
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  handleSave = async () => {
    const { idToken } = this.context.state;

    try {
      await architectService.updateArchitect(this.state.data, idToken);
      this.context.refreshData();
      this.props.handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { open, handleClose, classes } = this.props;
    const { data } = this.state;
    const { admin } = this.context.state;

    //Precaution as a race condition is present when mappnig the props to the state on mounting. See componentDidMount
    if (data === null) return null;
    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{data.name}</DialogTitle>
        <DialogContent>
          <div className="m-2">
            <Grid container spacing={24} justify="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  name="name"
                  label="Firm Name"
                  value={data["name"]}
                  fullWidth
                  autoComplete="Firm-name"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  variant="outlined"
                  name="email"
                  label="Firm Email"
                  value={data["email"]}
                  fullWidth
                  autoComplete="Firm-email"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  variant="outlined"
                  name="website"
                  label="Firm Website"
                  value={data["website"]}
                  fullWidth
                  autoComplete="Firm-website"
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  multiline
                  rows="3"
                  required
                  name="tagline"
                  value={data["tagline"]}
                  label="Tagline"
                  helperText="Describe your Firm in a single phrase. Max 140 Characters"
                  placeholder="Describe your Firm in a single phrase"
                  fullWidth
                  autoComplete="Firm s-Description"
                  onChange={this.handleChange}
                  inputProps={{ maxLength: 140 }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  multiline
                  rows={6}
                  variant="outlined"
                  name="description"
                  label="Description"
                  value={data["description"]}
                  helperText="Describe your Firm in more detail. Max 300 Characters"
                  placeholder="Describe your Firm in more detail ."
                  fullWidth
                  autoComplete="Firm l-Description"
                  onChange={this.handleChange}
                  inputProps={{ maxLength: 300 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="status-select"
                  >
                    Status
                  </InputLabel>
                  <Select
                    disabled={!admin}
                    value={data["status"]}
                    onChange={this.handleChange}
                    input={
                      <OutlinedInput
                        labelWidth={50}
                        name="status"
                        id="status-select"
                        // disabled={!admin}
                      />
                    }
                  >
                    <MenuItem value={"verified"}>Verified</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"declined"}>Declined</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.handleSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ArchitectDialog.contextType = AppContext;

export default withStyles(styles)(ArchitectDialog);
