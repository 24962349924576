import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import EnhancedTable from "components/ArchitectTable";
import ArchitectDialog from "components/ArchitectDialog";
import { AppContext } from "AppProvider";
import { Grid } from "@material-ui/core";
import NewForm from "components/Firm/NewForm";
const drawerWidth = 240;

const styles = theme => ({
  flex: {
    flex: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
    //marginLeft: drawerWidth
  },
  getStartedMessage: {
    marginTop: "40vh"
  },
  toolbar: theme.mixins.toolbar
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Firm extends React.Component {
  state = {
    selectedFirm: null,
    newFormOpen: false,
    editDialogOpen: false
  };

  handleEditFirm = Firm => {
    var result = this.context.state.userArchitects.filter(obj => {
      return obj.id === Firm;
    });
    this.setState({ selectedFirm: result[0], editDialogOpen: true });
  };

  renderNewForm = () => {
    if (this.state.newFormOpen) {
      return <NewForm />;
    }
    return null;
  };

  renderEditDialog = () => {
    const { selectedFirm, editDialogOpen } = this.state;
    if (editDialogOpen && selectedFirm !== null) {
      return (
        <ArchitectDialog
          data={selectedFirm}
          open={true}
          handleClose={() =>
            this.setState({ editDialogOpen: false, selectedFirm: null })
          }
        />
      );
    }
    return null;
  };

  renderGetStarted = () => {
    const { classes } = this.props;
    const { newFormOpen } = this.state;
    const { userArchitects } = this.context.state;
    if (userArchitects != null && userArchitects.length === 0 && !newFormOpen) {
      return (
        <React.Fragment>
          <Grid
            className={classes.getStartedMessage}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs="12">
              <Typography variant="h4">
                Looks like you haven't setup a Firm yet.
              </Typography>
            </Grid>
            <Grid className="text-center">
              <Button
                className="my-4"
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ newFormOpen: true })}
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  };

  renderUserData = () => {
    const { userArchitects } = this.context.state;
    if (userArchitects != null && userArchitects.length > 0) {
      return (
        <EnhancedTable handleEdit={this.handleEditFirm} data={userArchitects} />
      );
    }
    return null;
  };

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              My Companies
            </Typography>
            <Button color="inherit" onClick={onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {/* <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <List>
            <ListItem button>
              <ListItemText primary="Companies" />
            </ListItem>
          </List>
        </Drawer> */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.renderGetStarted()}
          {this.renderNewForm()}
          {this.renderEditDialog()}
          {this.renderUserData()}
        </main>
      </Dialog>
    );
  }
}

Firm.propTypes = {
  classes: PropTypes.object.isRequired
};

Firm.contextType = AppContext;
export default withStyles(styles)(Firm);
