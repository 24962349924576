import React, { Component } from "react";
import Joi from "joi-browser";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MapGL, { Marker } from "react-map-gl";
import ArchitectPin from "components/Map/ArchitectPin";
import Dropzone from "react-dropzone";
import { ForwardGeocode } from "services/GoogleGeocoderService";
import GoogleGeocoder from "components/common/GoogleGeocoder";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import config from "config";
import ChipInput from "./common/ChipInput";

const MAPBOX_TOKEN = config.mapbox.TOKEN;

const MapContainer = styled.div`
  width: 100%;
  height: 200px;
`;
const LogoFrame = styled.img`
  margin: 10px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  cursor: pointer;
`;

const types = [
  "Select your firm type",
  "Acoustic Consultant",
  "Architecture/Design Firm",
  "AV / IT Consultant",
  "Civil Engineer",
  "Code / ADA Consultant",
  "Construction Firm",
  "Cultural Institution",
  "Designer",
  "Distributor",
  "Educational Institution",
  "Engineering Firm",
  "Expediter",
  "Fabricator",
  "Facade / Enclosure Consultant",
  "Facade Consultant",
  "Facade Maintenance Consultant",
  "Fire Protection / Fire Alarm Engineer",
  "Geotechnical Engineer",
  "Landscape Consultant",
  "Land Use Counsel / Zoning Consultant",
  "LEED Consultant",
  "Lighting Consultant",
  "Manufacturer",
  "Marketing Agency",
  "MEP Engineer",
  "Other",
  "Photography Studio",
  "Publisher",
  "Real Estate Development",
  "Rendering / Visualization",
  "Reseller",
  "Retailer",
  "Security Consultant",
  "Software Developer",
  "Structural Engineer",
  "Vertical Transportation Consultant"
];

const projectTags = [
  "Bridge",
  "Commercial",
  "Concept",
  "Dam",
  "Education",
  "Energy Engineering",
  "Farm",
  "Governmental",
  "Greenhouse",
  "Healthcare",
  "House/Villa",
  "Industrial",
  "Interior",
  "Landscape",
  "Masterplan",
  "Museum",
  "Residential",
  "Offices",
  "Oil Platform",
  "Powerplant",
  "Religious",
  "Skyscraper",
  "Sports Stadium"
].map(option => ({ label: option, value: option }));

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  field: {
    marginTop: 10
  }
});

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

class FirmForm extends Component {
  schema = {
    // "Firm Logo": Joi.object(),
    "Firm Name": Joi.string()
      .max(100)
      .required(),
    "Firm Email": Joi.string()
      .email({ minDomainAtoms: 2 })
      .min(4)
      .required(),
    "Firm Website": Joi.string()
      .regex(
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
      )
      .min(4)
      .required(),
    "Firm Description": Joi.string()
      .min(4)
      .max(150)
      .required(),
    "Firm Address": Joi.object().required(),
    "Firm Type": Joi.string().required(),
    "Project Tags": Joi.array().items(Joi.string())
  };

  state = {
    data: [],
    errors: [],
    viewport: {
      latitude: 37.7577,
      longitude: -122.4376,
      zoom: 8
    },
    addressOptions: null,
    searchResult: null,
    acceptedFiles: []
  };

  mapRef = React.createRef();

  isValid = () => {
    if (isEmpty(this.validate())) {
      return true;
    } else {
      return false;
    }
  };

  handleViewportChange = viewport => {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  };

  handleGeocoderViewportChange = viewport => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleOnResult = event => {
    const { handleChange } = this.props;
    console.log(event.result);
    this.setState({
      searchResult: event.result
    });
    handleChange(event.result);
  };

  handleOnDropFiles = () => {};

  validateProperty = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const { onValidate } = this.props;
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) {
      if (onValidate) onValidate(true);
      return null;
    }
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    this.setState({ errors });
    if (onValidate) onValidate(false);
    return errors;
  };

  handleChange = (name, value) => {
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(name, value);

    // if (errorMessage) errors[name] = errorMessage;
    // else delete errors[name];

    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });

    if (this.props.onChange != null) {
      this.props.onChange(data);
    }
  };

  handleAddressChange = value => {
    const address = { label: value.formatted_address, data: value };

    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    console.log(address);

    const viewport = { ...this.state.viewport };
    viewport.latitude = address.data.geometry.location.lat;
    viewport.longitude = address.data.geometry.location.lng;
    viewport.zoom = 16;

    this.handleChange("Firm Address", address);

    return this.handleViewportChange({
      ...viewport,
      ...geocoderDefaultOverrides
    });
  };

  handleInputChange = ({ target: input }) => {
    this.handleChange(input.name, input.value);
  };

  handleDropdownChange = (name, value) => {
    const index = types.indexOf(value);
    if (index === 0) {
      //In the case where you already selected a value from the dropdown and then return to select index 0 it wouldn't update the state.
      if (this.state.data[name] !== undefined) {
        const data = { ...this.state.data };
        delete data[name];
        this.setState({ data });
      }

      return;
    }
    this.handleChange(name, value);
  };

  render() {
    const { viewport, data, errors } = this.state;
    const { classes } = this.props;
    const logo = data["Firm Logo"];

    return (
      <div>
        <Grid
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {/* <Grid item>
            <Dropzone
              accept=".png, .jpg"
              options
              onDrop={acceptedFiles =>
                this.handleChange("Firm Logo", acceptedFiles[0])
              }
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <div {...getRootProps()}>
                    <Typography>Firm Logo *</Typography>
                    {logo && <LogoFrame src={URL.createObjectURL(logo)} />}
                    {!logo && (
                      <LogoFrame src={require("images/logofield.svg")} />
                    )}

                    <input {...getInputProps()} />
                  </div>
                );
              }}
            </Dropzone>
          </Grid>          
           <Grid item xs={12} />

          */}

          <Grid item className={classes.field} xs={12} md={6}>
            {this.renderTextField(
              "Firm Name",
              "text",
              "'Firm Name' is required",
              "100"
            )}
          </Grid>
          <Grid item className={classes.field} xs={12} md={6}>
            {this.renderTextField(
              "Firm Email",
              "email",
              "'Firm Email' must be a valid email"
            )}
          </Grid>
          <Grid item className={classes.field} xs={12} md={12}>
            {this.renderTextField(
              "Firm Website",
              "text",
              "'Firm Website' must be a valid url"
            )}
          </Grid>
          <Grid item className={classes.field} xs={12} md={12}>
            {this.renderTextField(
              "Firm Description",
              "text",
              "'Firm Description' is required and must be less than 150 characters.",
              "150"
            )}
          </Grid>
          <Grid item className={classes.field} xs={12} md={12}>
            <ChipInput
              label="Project Tags"
              helperText="Tags make it easier for others to find your firm"
              placeholder={
                data["Project Tags"] === undefined ? "Search for tags..." : ""
              }
              onChange={value => this.handleChange("Project Tags", value)}
              options={projectTags}
              allowCreating
              // errorMessage={"'Firm"}
            />
          </Grid>
          <Grid item className={classes.field} xs={12} md={12}>
            <TextField
              select
              label="Firm Type"
              fullWidth
              required
              value={data["Firm Type"] ? data["Firm Type"] : types[0]}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              // helperText="Please select your interest"
              // margin="normal"
              variant="outlined"
              onChange={({ target }) =>
                this.handleDropdownChange("Firm Type", target.value)
              }
              error={errors["Firm Type"] !== undefined}
              helperText={
                errors["Firm Type"] !== undefined
                  ? "Please select your firm type."
                  : ""
              }
            >
              {types.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={classes.field} xs={12} md={12}>
            <GoogleGeocoder
              label="Search for your firm address"
              onChange={this.handleAddressChange}
              errorMessage={
                errors["Firm Address"] !== undefined
                  ? "Please search for your firm's address."
                  : ""
              }
            />
          </Grid>

          <Grid item className={classes.field} xs={12}>
            <MapContainer>
              <MapGL
                width="100%"
                height="100%"
                ref={this.mapRef}
                {...viewport}
                onViewportChange={this.handleViewportChange}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                dragPan={window.innerWidth >= 600}
                dragRotate={window.innerWidth >= 600}
              >
                {data["Firm Address"] != null && (
                  <Marker
                    latitude={data["Firm Address"].data.geometry.location.lat}
                    longitude={data["Firm Address"].data.geometry.location.lng}
                  >
                    <ArchitectPin size={30} />
                  </Marker>
                )}
              </MapGL>
            </MapContainer>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderTextField(name, type, errorMessage, maxLength) {
    const { errors } = this.state;
    const { classes } = this.props;
    const error = errors[name];
    return (
      // <Tooltip title={error} disableFocusListener open={false}>
      <TextField
        name={name}
        label={name}
        required
        inputProps={{ maxLength: maxLength }}
        fullWidth
        // type={type}
        variant="outlined"
        error={errors[name] !== undefined}
        onChange={this.handleInputChange}
        helperText={errors[name] !== undefined ? errorMessage : ""}
        // onBlur={this.handleInputChange}
      />
      // </Tooltip>
    );
  }
}

export default withStyles(styles)(FirmForm);
