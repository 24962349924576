import React from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.created ? `Create: ${suggestion.label}` : suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

class ChipInput extends React.Component {
  state = {
    inputValue: "",
    selectedItem: []
  };

  getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    if (inputValue.length === 0) return [];

    let suggestions = [];

    suggestions = this.props.options.filter(suggestion => {
      const keep =
        count < 5 &&
        suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }

      return keep;
    });

    const containsInputValue = suggestions.find(suggestion => {
      return suggestion.label.toLowerCase() === inputValue.toLowerCase();
    });

    if (!containsInputValue) {
      suggestions.push({ label: inputValue, created: true });
    }

    return suggestions;
  };

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;

    switch (event.key) {
      case "Backspace": {
        if (selectedItem.length && !inputValue.length) {
          this.setState({
            selectedItem: selectedItem.slice(0, selectedItem.length - 1)
          });
        }
        break;
      }
      // case "Enter": {
      //   if (
      //     !this.getSuggestions(inputValue).find(suggestion => {
      //       return (
      //         suggestion.label === `Created: ${inputValue}` &&
      //         suggestions.created
      //       );
      //     })
      //   ) {
      //     this.handleChange(inputValue);
      //     break;
      //   }
      // }
      default:
        return;
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = item => {
    let { selectedItem } = this.state;

    if (selectedItem.indexOf(item) === -1) {
      selectedItem = [...selectedItem, item];
    }

    this.setState({
      inputValue: "",
      selectedItem
    });

    this.props.onChange(selectedItem);
  };

  handleDelete = item => () => {
    const selectedItem = [...this.state.selectedItem];
    selectedItem.splice(selectedItem.indexOf(item), 1);
    this.setState({ selectedItem });
    this.props.onChange(selectedItem);
  };

  render() {
    const { classes, label, placeholder, helperText } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => {
          const suggestions = this.getSuggestions(inputValue2);

          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                variant: "outlined",
                helperText: helperText,
                classes,
                InputProps: getInputProps({
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={this.handleDelete(item)}
                    />
                  )),
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  placeholder: placeholder
                }),
                label: label
              })}
              {isOpen && inputValue2.length > 0 ? (
                <Paper className={classes.paper} square>
                  {suggestions.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.label }),
                      highlightedIndex,
                      selectedItem: selectedItem2
                    })
                  )}

                  {/* {!suggestions.find(suggestion => {
                    return (
                      suggestion.label.toLowerCase() ===
                      inputValue2.toLowerCase()
                    );
                  }) ? (
                    <MenuItem
                      {...getItemProps({ item: inputValue2 })}
                      key={inputValue}
                      selected={false}
                      component="div"
                      style={{
                        fontWeight: 400
                      }}
                    >
                      {`Create: ${inputValue}`}
                    </MenuItem>
                  ) : null} */}
                </Paper>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  inputRoot: {
    flexWrap: "wrap",
    padding: 5
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

ChipInput.propTypes = {
  classes: PropTypes.object.isRequired,
  allowCreating: PropTypes.bool
};

export default withStyles(styles)(ChipInput);
