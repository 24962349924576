import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`
  },
  total: {
    fontWeight: "700"
  },
  title: {
    marginTop: theme.spacing.unit * 2
  }
});

function Review(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Please verify the information you provided us!
      </Typography>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" name="corrVerify" value="yes" />}
          label="I verify that the all the details I gave are correct and I have the authority from my Firm to provide them."
        />
        <FormControlLabel
          control={<Checkbox color="secondary" name="corrVerify" value="yes" />}
          label="I agree with Parametricos Terms and Conditions."
        />
      </Grid>
    </React.Fragment>
  );
}

Review.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Review);
