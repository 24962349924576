import React, { PureComponent } from "react";
import styled from "styled-components";
import { Grid, Typography, Avatar, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Mail, Share } from "@material-ui/icons";
const Container = styled.div`
  max-width: 200px;
`;

const styles = {
  avatar: {
    margin: 10
  },
  bigAvatar: {
    width: 80,
    height: 80,
    top: -40,
    margin: "auto",
    left: 0,
    right: 0,
    position: "absolute"
  },
  grid: { marginTop: 40 }
};

class ArchitectInfo extends PureComponent {
  render() {
    const { info, classes } = this.props;
    const displayName = info.name;

    return (
      <React.Fragment>
        <Container>
          <Avatar
            alt="Firm Image"
            src={require("Asset 55.png")}
            className={classes.bigAvatar}
          />

          <Grid
            container
            spacing={16}
            direction="column"
            justify="space-around"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item>
              <Typography variant="h6" className="text-center">
                {displayName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{info.tagline}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    onClick={() =>
                      (window.location.href = `mailto:${info.email}`)
                    }
                  >
                    <Mail />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton className={classes.button} aria-label="Delete">
                    <Share />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ArchitectInfo);
