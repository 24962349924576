import React, { Component } from "react";
import { Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import { AppContext } from "../AppProvider";
import Button from "components/common/Button";
import ReactGA from "react-ga";

class ThankYouMessage extends Component {
  componentDidMount() {
    ReactGA.modalview("Post submission - Thank you");
  }

  render() {
    return (
      <Dialog fullWidth maxWidth="sm" open={true}>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={8}
            alignContent="stretch"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={10}>
              <Typography variant="h3" align="center">
                Thanks, that's it!
              </Typography>
            </Grid>
            <Grid item xs={12} className="my-4">
              <Typography variant="subtitle1" align="center">
                We'll notify you as soon as 'find an architect' goes live. In
                the meantime, why not check out Parametricos Studio, our
                flagship product?
              </Typography>
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12}>
              <Grid container direction="row" spacing={16} justify="center">
                <Grid item>
                  <Button
                    fullWidth={true}
                    variant="outlined"
                    color="primary"
                    href="https://parametricos.com/allfeatures"
                    target="_blank"
                    // onClick={() =>
                    //   (window.location = "https://parametricos.com/allfeatures")
                    // }
                  >
                    Studio
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    href="https://parametricos.com/"
                    target="_blank"
                  >
                    Home
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

ThankYouMessage.contextType = AppContext;

export default ThankYouMessage;
