import React, { Component } from "react";
import Map from "components/Map/Map";
import NavBar from "components/NavBar";
import Admin from "screens/Admin";
import Firm from "screens/Firm";
import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Grid,
  TextField,
  Hidden
} from "@material-ui/core";

import AppProvider, { AppContext } from "AppProvider";
import MenuAppBar from "../../components/MenuAppBar";
import FindAnArchitectLogo from "images/logo/findanarchitect.png";
import FirmForm from "../../components/FirmForm";
import FirmFormDialog from "../../components/FirmFormDialog";
import WelcomeDialog from "../../components/WelcomeDialog";
const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  bottom: 0;
  position: absolute;
`;

const FilterContainer = styled.div`
  position: absolute;
  margin-top: 20px;
  z-index: 500;
  width: 99%;
`;

const FilledOutlinedField = styled(TextField)`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  border-style: solid;
`;

const LogoContainer = styled.div`
  margin-top: 20px;
`;

class Home extends Component {
  state = {
    adminOpen: false,
    companiesOpen: false
  };

  handleOpenCompanies = () => {
    this.setState({ companiesOpen: true });
  };

  handleOpenAdmin = () => {
    this.setState({ adminOpen: true });
  };

  handleCloseCompanies = () => {
    this.setState({ companiesOpen: false });
  };

  handleCloseAdmin = () => {
    this.setState({ adminOpen: false });
  };

  render() {
    return (
      <div>
        {/* <MenuAppBar
          handleOpenCompanies={this.handleOpenCompanies}
          handleOpenAdmin={this.handleOpenAdmin}
        /> */}
        {/* <Button onClick={this.context.login}>Login</Button> */}
        <Admin open={this.state.adminOpen} onClose={this.handleCloseAdmin} />
        <Firm
          open={this.state.companiesOpen}
          onClose={this.handleCloseCompanies}
        />
        <FilterContainer>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            spacing={8}
          >
            <Grid item xs={10} sm={10} md={8}>
              <LogoContainer>
                <img src={FindAnArchitectLogo} height="35px" />
              </LogoContainer>
            </Grid>
            <Grid item xs={10} sm={10} md={8}>
              <FilledOutlinedField
                variant="outlined"
                fullWidth
                label="Search by location..."
              />
            </Grid>
            <Hidden xsDown={true}>
              <Grid item sm={12} />
              <Grid item sm={10} md={8}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={8}
                >
                  <Grid item xs={6} sm={3}>
                    <FilledOutlinedField
                      variant="outlined"
                      fullWidth
                      label="Firm Type"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FilledOutlinedField
                      variant="outlined"
                      fullWidth
                      label="Project Type"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FilledOutlinedField
                      variant="outlined"
                      fullWidth
                      label="Rating"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FilledOutlinedField
                      variant="outlined"
                      fullWidth
                      label="Project Location"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </FilterContainer>
        <WelcomeDialog />
        <MapContainer>
          <Map />
        </MapContainer>
      </div>
    );
  }
}

Home.contextType = AppContext;

export default Home;
