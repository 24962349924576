import React, { Component } from "react";
import { CreateFirm } from "services/FirmService";
import FirmForm from "components/FirmForm";
import { Typography, Grid, Link } from "@material-ui/core";
import Button from "components/common/Button";

export default class CreateFirmForm extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      data: null,
      loading: false
    };
  }

  handleSubmit = async () => {
    const { data } = this.state;

    if (!this.form.current.isValid()) return;
    this.setState({ loading: true });

    const firm = {
      content: {
        name: data["Firm Name"],
        email: data["Firm Email"],
        website: data["Firm Website"],
        tagline: data["Firm Description"],
        speciality: data["Firm Speciality"],
        tags: data["Project Tags"],
        description: data["Firm Description"],
        address: data["Firm Address"]
      }
    };

    try {
      await CreateFirm(firm);
      window.location.reload();
    } catch (error) {
      this.setState({ loading: false });
      alert(
        "There was a problem submitting your information. Please try again or come back later."
      );
      console.error(error);
    }
  };

  handleFormChange = data => {
    this.setState({ data: data });
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        // justify="flex-end"
        alignContent="center"
        spacing={16}
      >
        <Grid item xs={12}>
          <FirmForm innerRef={this.form} onChange={this.handleFormChange} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="caption">
            By clicking submit, you consent to allow Parametricos to store and
            process the personal information submitted above to contact and
            provide you the content requested.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.loading}
            onClick={this.handleSubmit}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  }
}
